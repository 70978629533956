@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'),
    url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'),
    url('./assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'),
    url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}
